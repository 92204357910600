<script>
import { nanoid } from 'nanoid'
import uploadcare from 'uploadcare-widget'
import Vue, { ref } from 'vue'
import cdn from '/~/utils/cdn'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.v2.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import { useRecProfile } from '/~rec/composables/profile'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'

export default {
  name: 'drawer-avatar-modal',
  components: {
    DrawerIconHeader,
    BaseButton,
    BaseIcon,
  },
  setup() {
    const { isBupaTemplate, isAvatarEditable } = useProvider()
    const { isDarkThemeForEwallet } = useCms()
    const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()
    const { updateProfile } = useRecProfile()
    const { user, fetchUploadKey, updateUserDetails } = useUser()
    const isShowText = ref(!isBupaTemplate.value)
    const keyboardAccessAreaId = nanoid()
    const uploadButtonElement = ref(null)

    return {
      fetchUploadKey,
      isDarkThemeForEwallet,
      isShowText,
      keyboardAccessAreaId,
      lockKeyboardAccessArea,
      unlockKeyboardAccessArea,
      updateProfile,
      updateUserDetails,
      uploadButtonElement,
      user,
      isAvatarEditable,
      cdn,
    }
  },
  data() {
    return {
      form: {},
      submitting: false,
      uploadcare: null,
      oldPicture: null,
    }
  },
  mounted() {
    this.initUploadcare()
    this.form = {
      picture: this.user.avatarUrl,
    }
  },
  methods: {
    async initUploadcare() {
      try {
        this.uploadcare = await this.fetchUploadKey()
      } catch (e) {
        // console.error(e)
      } finally {
        this.submitting = false
      }
    },
    async save(form) {
      this.submitting = true

      const payload = {}

      if (form.picture === '') {
        payload.avatar = ''
        payload.avatar_modifiers = ''
      } else if (form.picture !== this.user.avatarUrl) {
        payload.avatar = form.RAWPicture || ''
        payload.avatar_modifiers = form.modifiers || ''
      }

      try {
        const user = await this.updateUserDetails(payload)

        this.updateProfile(user.avatarUrl)
      } catch (error) {
        Vue.notify({
          text: 'Something went wrong while uploading. Please try again.',
          type: 'error',
          duration: 5000,
        })
        this.form.picture = this.oldPicture
      } finally {
        this.submitting = false
      }
    },
    async onUpload() {
      this.submitting = true

      const dialog = uploadcare.openDialog(null, {
        public_key: this.uploadcare.public_key,
        imagesOnly: true,
        crop: '500x500',
        tabs: 'file camera',
      })

      dialog.done((filePromise) => {
        filePromise.done((file) => {
          this.oldPicture = this.form.picture
          this.form.picture = file.cdnUrl
          this.form.RAWPicture = `uploadcare://${file.uuid}`
          this.form.modifiers = file.cdnUrlModifiers
          this.submitting = false
          this.save({
            ...this.form,
          })
        })

        filePromise.fail((error) => {
          this.submitting = false

          Vue.notify({
            text: 'Something went wrong while uploading. Please try again.',
            type: 'error',
            duration: 5000,
          })
          console.error(error)
        })
      })

      dialog.fail(() => {
        this.submitting = false
      })

      dialog.always(() => {
        setTimeout(() => {
          this.uploadButtonElement?.$el.focus()
          this.unlockKeyboardAccessArea(this.keyboardAccessAreaId)
        })
      })

      setTimeout(() => {
        this.lockKeyboardAccessArea({
          id: this.keyboardAccessAreaId,
          rootElement: document.querySelector(
            '.uploadcare--dialog_status_active .uploadcare--dialog__container'
          ),
          previousActiveElement: this.$refs.uploadButtonElement?.$el,
          delay: 100,
        })
      })
    },
    async removeProfilePhoto() {
      await this.save({
        ...this.form,
        picture: '',
      })
      this.form.picture = ''
    },
    hide() {
      this.$emit('hide')
    },
  },
}
</script>

<template>
  <div
    :class="{
      'bg-gray-900 text-white': isDarkThemeForEwallet,
    }"
  >
    <drawer-icon-header rounded animated class="font-inter" :action="hide">
      <template #iconHeader></template>
      <template #icon>
        <img
          v-if="form.picture"
          class="h-full w-full rounded-full border-4 border-white shadow-md"
          alt="profile image"
          :src="
            cdn(form.picture)
              .preview('240x240')
              .format('auto')
              .quality('smart')
              .url()
          "
        />
        <base-icon
          v-else
          class="flex min-h-full min-w-full items-center justify-center"
          svg="australiapost/profile-red"
          size="md"
        />
      </template>
      <template v-if="isShowText" #title>Edit your photo</template>
    </drawer-icon-header>
    <div class="px-5">
      <div class="mx-5 mb-10 text-center text-eonx-neutral-600">
        <span v-if="isShowText">
          Update your profile picture so other users can see it and recognise
          you easily.
        </span>
      </div>
      <div class="w-full pb-5 font-inter">
        <base-button
          v-if="isAvatarEditable"
          ref="uploadButtonElement"
          :disabled="!uploadcare || submitting"
          full-width
          @click="onUpload"
        >
          {{ form.picture ? 'Update photo' : '+ Add photo' }}
        </base-button>
        <base-button
          v-if="isAvatarEditable && form.picture"
          :disabled="!uploadcare || submitting"
          full-width
          class="mt-1.5"
          color="error"
          @click="removeProfilePhoto"
        >
          Remove photo
        </base-button>
        <base-button
          v-if="isAvatarEditable"
          :disabled="!uploadcare || submitting"
          full-width
          class="mt-1.5"
          color="white"
          @click="hide"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>
